<template lang="pug">
  include /mixins
  +b.user-bar(
    :class="{'is-active': isActive, 'user-bar--variant_1': !isCabinet}"
    v-click-outside="hideUserBar"
  )

    +e.heading(@click.prevent="toggleUserBar")
      slot
        +b.g-row.--align_center.--justify_between
          +b.g-cell.g-cols.--auto-xs
            +b.user-info.--variant_1
              +e.wrapper.--centered
                +e.avatar
                  +b.circle-button.is-not-interactive(
                    v-if="!user.info.avatar"
                    :class="isCabinet ? 'circle-button--relief_9' : 'circle-button--variant_1 circle-button--bg_dark'"
                  )
                    +e.icon
                      +b.I.icon-users
                  +b.i-avatar--variant_1(v-else)
                    +e.IMG.image(:src="user.info.avatar")
                +e.content(:class="{'is-visible-fhd': isCabinet, 'is-visible-xl': !isCabinet}")
                  +b.P.ds-caption.--size_4xs.--size_2xs-xl(
                    :class="{'ds-caption--color_dark': isCabinet, 'ds-caption--color_white': !isCabinet}"
                  ) {{ user.info.title }}
                  +b.ds-panel.--space_sm
                    +e.element.--offset_top
                      +b.P.ds-caption.--size_4xs.--size_2xs-xl(
                        :class="{'ds-caption--color_dark': isCabinet, 'ds-caption--color_white': !isCabinet}"
                      ) {{ user.info.user.email }}
          +b.g-cell.g-cols.--narrow-xs(:class="{'is-visible-fhd': isCabinet, 'is-visible-xl': !isCabinet}")
            +b.ds-inliner.--size_xxs(
              :class="{'ds-inliner--color_relief-9': isCabinet, 'ds-inliner--color_white': !isCabinet}"
            )
              +e.body(:class="{'is-rotate': isActive}")
                +b.I.icon-dropdown
    +e.wrapper(v-show="isActive")
      +e.item.--variant_1(v-if="isCabinet")
        language-switch
        +b.ds-panel.--space_lg
          +e.element.--offset_top
            currency-switch
      +e.item.--variant_2
        +b.user-info.--variant_1
          +e.wrapper.--centered
            +e.avatar
              +b.circle-button.is-not-interactive(
                v-if="!user.info.avatar"
                :class="{'circle-button--relief_9': isCabinet, 'circle-button--variant_3': !isCabinet}"
              )
                +e.icon
                  +b.I.icon-users
              +b.i-avatar(v-else)
                +e.IMG.image(:src="user.info.avatar")
            +e.content
              +b.P.ds-caption.--size_4xs.--size_2xs-xl(
                :class="{'ds-caption--color_dark': isCabinet, 'ds-caption--color_white': !isCabinet}"
              ) {{ user.info.title }}
        +b.ds-panel.--space_sm
          +e.element.--offset_top
            +b.P.ds-caption.--size_4xs.--size_2xs-xl(
              :class="{'ds-caption--color_dark': isCabinet, 'ds-caption--color_white': !isCabinet}"
            ) {{ user.info.user.email }}
      user-access-state(
        :permissions="[$allPermissions.PROFILE.TYPES.IS_INVESTOR, $allPermissions.INVESTOR_LANDS.LANDS.VIEW]"
        :excluded-permission='$allPermissions.PROFILE.TYPES.IS_ADMIN'
        v-slot="{ hasAccess }"
      )
        +e.item.is-prevent-use-item(v-if="hasAccess")
          +b.A.i-link.--size_3xs(
            :class="{'i-link--color_white': !isCabinet}"
            :href="$router.resolve({ name: 'profile:land:list' }).href"
          )
            +e.icon
              +b.I.icon-list
            +e.SPAN.text {{ _("Ваш земельний банк") }}
              |&nbsp;
              b ({{user.info.totalArea}} {{ _("га") }})
              |&nbsp;
      +e.item
        user-access-state(
          :permissions="[$allPermissions.ADS.ADS.VIEW]"
          v-slot="{ hasAccess }"
        )
          +b.ds-panel.--space_md.is-prevent-use-item(v-if="hasAccess")
            +e.element.--offset_bottom
              +b.ROUTER-LINK.i-link.--size_3xs(
                v-if="isCabinet"
                :to="{ name: 'profile:map' }"
              )
                +e.icon
                  +b.I.icon-catalog
                +e.SPAN.text {{ _("Карта объявлений") }}
              +b.A.i-link.--size_3xs.--color_white(
                v-if="!isCabinet"
                :href="profileMapUrl"
              )
                +e.icon
                  +b.I.icon-catalog
                +e.SPAN.text {{ _("Карта объявлений") }}
        +b.ds-panel.--space_md
          +e.element.--offset_bottom
            modal-trigger(
              name='PasswordChange',
              url='Auth/components/PasswordChange',
              :classes='["vm--modal_sm"]'
              :isCabinet="isCabinet"
            )
              template(#default='{ open }')
                +b.i-link.--size_3xs(
                  @click.prevent="open"
                  :class="{'i-link--color_white': !isCabinet}"
                )
                  +e.icon
                    +b.I.icon-update
                  +e.SPAN.text {{ _("Изменить пароль") }}
        //- +b.ds-panel.--space_md
        //-   +e.element.--offset_bottom
        //-     +b.i-link.--size_3xs(
        //-       :class="{'i-link--color_white': !isCabinet}"
        //-     )
        //-       +e.icon
        //-         +b.I.icon-users
        //-       +e.SPAN.text {{ _("Мій профіль") }}
        +b.i-link.--size_3xs(
          @click.prevent="logout"
          :class="{'i-link--color_white': !isCabinet}"
        )
          +e.icon
            +b.I.icon-logout
          +e.SPAN.text {{ _("Вихід") }}
      user-access-state(
        :permissions="[$allPermissions.PROFILE.TYPES.IS_INVESTOR]"
        :excluded-permission='$allPermissions.PROFILE.TYPES.IS_ADMIN'
        v-slot="{ hasAccess }"
      )
        +e.item(v-if="hasAccess")
          +b.P.ds-caption.--size_3xs.--bold(
            :class="isCabinet ? 'ds-caption--color_dark' : 'ds-caption--color_white'"
          ) {{ _("Ваш менеджер") }}
          +b.ds-panel.--space_sm
            +e.element.--offset_top
              div(v-if="user.info.manager && user.info.manager.id")
                +b.P.ds-caption.--size_3xs(
                  :class="isCabinet ? 'ds-caption--color_dark' : 'ds-caption--color_white'"
                ) {{ user.info.manager.title }}
                +b.P.ds-caption.--size_3xs(
                  :class="isCabinet ? 'ds-caption--color_dark' : 'ds-caption--color_white'"
                ) {{ user.info.manager.phone }}
              div(v-else)
                +b.P.ds-caption.--size_3xs(
                  :class="isCabinet ? 'ds-caption--color_dark' : 'ds-caption--color_white'"
                ) {{ _("Менеджер КупиПай") }}
                +b.P.ds-caption.--size_3xs(
                  :class="isCabinet ? 'ds-caption--color_dark' : 'ds-caption--color_white'"
                ) {{ organizationPhone }}
</template>

<script>
import { mapState } from 'vuex'
import { userLogoutResource } from '@api/auth.service'

export default {
  name: 'UserBar',

  props: {
    isCabinet: {
      default: true,
    },
  },

  data() {
    return {
      isActive: false,
      profileUrl: window.profileUrl,
      organizationPhone: window.organizationPhone,
    }
  },

  computed: {
    profileMapUrl() {
      return `${this.profileUrl}map/`
    },
    ...mapState('users/auth', [
      'user',
    ]),
  },

  methods: {
    toggleUserBar() {
      this.isActive = !this.isActive
    },

    hideUserBar() {
      this.isActive = false
    },

    logout() {
      return userLogoutResource.execute().then(() => {
        window.location = window.indexPageUrl
      })
    },
  },
}
</script>
