<template lang="pug">
  include /mixins
  +b.HEADER.i-header
    +e.wrapper
      +e.logo
        +b.A.logo-wrapper(:href="indexPageUrl")
          +e.IMG.logo(:src="headerLogo")
      +e.part.--variant_1
        language-switch
      +e.part.--variant_2
        currency-switch
      +e.part.--variant_1.is-prevent-use-item
        ui-search
      +e.part
        +b.g-row.--appearance_spaced
          +b.g-cell.is-disable-xl.is-prevent-use-item
            ui-search
          //- user-access-state(
          //-   :permissions="[$allPermissions.CART.CART.EDIT]",
          //-   v-slot="{ hasAccess }"
          //- )
          //-   template(v-if="hasAccess")
          //-     +b.g-cell
          //-       +b.ROUTER-LINK.circle-button.--variant_1(
          //-         :to="{name: 'profile:notification:list'}"
          //-       )
          //-         +e.icon
          //-           +b.I.icon-notification
          //-         +b.i-circle
          //-           +e.SPAN.text 5
          user-access-state(
            :permissions="[$allPermissions.CART.CART.EDIT]",
            v-slot="{ hasAccess }"
          )
            template(v-if="hasAccess")
              +b.g-cell.is-prevent-use-item(v-if="cart.items.length")
                modal-trigger(
                  key="cart"
                  name='Cart',
                  url='Cabinet/modules/cart/views/List',
                  :classes='["vm--modal_md"]'
                )
                  template(#default='{ open }')
                    +b.circle-button.--variant_1.is-active(@click.prevent="open")
                      +e.icon
                        +b.I.icon-cart
                      +b.i-circle
                        +e.SPAN.text {{ cart.items.length }}
              +b.g-cell.is-prevent-use-item(v-else)
                modal-trigger(
                  key="empty"
                  name='Cart',
                  url='Cabinet/modules/cart/views/Empty',
                  :classes='["vm--modal_md"]'
                )
                  template(#default='{ open }')
                    +b.circle-button.--variant_1.is-active(@click.prevent="open")
                      +e.icon
                        +b.I.icon-cart

          +b.g-cell.is-disable-xfhd
            user-bar
              +b.circle-button.--variant_1(
                v-if="!user.info.avatar"
              )
                +e.icon
                  +b.I.icon-users
              +b.i-avatar.--variant_1(v-else)
                +e.IMG.image(:src="user.info.avatar")
    +e.user
      user-bar
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CabinetHeader',

  data() {
    return {
      formdata: {
        search: null,
      },
      indexPageUrl: window.indexPageUrl,
      headerLogo: window.headerLogo,
    }
  },

  computed: {
    ...mapState('users/auth', ['user']),
    ...mapState('cart', ['cart']),
  },
}
</script>
