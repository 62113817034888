import { cartRetrieveResource, cartUpdateResource } from '@api/cart.service'
import { getParsedErrors } from '@app/Forms/FormMixin'

export function createStore() {
  return {
    namespaced: true,

    state: {
      cart: {
        items: [],
        announcementInfo: {},
      },
      cartUpdatedCount: 0,
    },

    actions: {
      async getCartState({ commit }) {
        const { data: { item } } = await cartRetrieveResource.execute()

        commit('CHANGE_CART_STATE', item)
      },

      async updateCartState({ commit }, { formdata, title, vm, additional }) {
        await cartUpdateResource.execute({}, formdata).then(res => {
          const { data: { item } } = res

          if (additional?.isAdded) {
            const modal = () => import('@app/Cabinet/modules/cart/views/Added')

            vm.$modal.show(modal, {}, {
              classes: ['vm--modal_md'],
            })
          } else {
            vm.$notify(title.success)
          }

          commit('CHANGE_CART_STATE', item)
        }).catch(async e => {
          const errors = await getParsedErrors(e)

          if (errors && errors.recaptcha) {
            vm.$notify({ text: vm._("Ваш комп'ютер або мережа може надсилати автоматизовані запити."), type: 'error' })
          } else {
            vm.$notify({ text: title.error, type: 'error' })
          }
        })
      },
    },

    mutations: {
      CHANGE_CART_STATE(state, result) {
        state.cart = result
      },

      CHANGE_CART_UPDATED_COUNT(state) {
        state.cartUpdatedCount += 1
      },
    },
  }
}
