import { createStore as authCreateStore } from '@md/auth'

import { profileRetrieveResource, profileUpdateResource } from '@api/profile.service'

export function createStore(parameters) {
  return {
    namespaced: true,

    modules: {
      auth: authCreateStore({
        receiveResource: profileRetrieveResource,
        patchResource: profileUpdateResource,
        ...parameters,
      }),
    },
  }
}
