<template lang="pug">
  include /mixins
  div
    +b.checkbox-filter(v-for="item in choices")
      +b.LABEL.d-control-checkbox.--variant_flex.--size_1(
        :class="{ 'is-pre-disabled': $options.STATUS_DISABLED.includes(item.value) }"
      )
        +e.additional
          +b.circle-hint(:class="$options.STATUS_CLASSES[item.value]")
        +e.element.INPUT(
          type="checkbox"
          :value="item.value",
          :name="filter.urlKey"
          v-model="checked"
          @change="submit()"
          :disabled="$options.STATUS_DISABLED.includes(item.value)"
        )
        +e.SPAN.label.--variant_bordered.--position_right
        +e.SPAN.content.--type_3xs.--color_dark.--space_right
          +b.g-row
            +b.g-cell
              span {{ item.label }}
            +b.g-cell
              +b.hint(v-tooltip.auto="{content: hints[item.value], trigger: 'click hover'}")
                +e.icon
                  +b.I.icon-info
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils'

const STATUS_CLASSES = {
  1: '',
  2: 'circle-hint--bg_danger',
  3: 'circle-hint--bg_purple',
  4: 'circle-hint--bg_red',
}

const STATUS_DISABLED = [1, 2]

export default {
  STATUS_CLASSES,
  STATUS_DISABLED,

  props: ['value', 'filter'],

  data() {
    return {
      checked: [],
      hints: {
        1: this._('Земельна ділянка яку можна купити'),
        2: this._('Земельна ділянка в процесі укладання угоди купівлі-продажу'),
        3: this._('Земельна ділянка в процесі укладання угоди купівлі-продажу зі мною'),
        4: this._('Куплена мною ділянка'),
      },
    }
  },

  watch: {
    value() {
      this.setInitialValue()
    },
  },

  computed: {
    choices() {
      return this.filter.choices.filter(item => 5 !== item.value)
    },
  },

  mounted() {
    this.setInitialValue()
  },

  methods: {
    setInitialValue() {
      if (isEmpty(this.value)) {
        this.checked = this.filter.initial
      } else {
        this.checked = this.normalize(this.value)
      }
    },

    resetValue() {
      this.checked = ''
    },

    submit() {
      if (isEmpty(this.checked)) {
        this.$emit('input', [5])
      } else {
        this.checked = this.checked.filter(val => 5 !== val)

        this.$emit('input', this.checked)
      }
    },

    normalize(value) {
      let val = value

      if (!val) return []

      if (!Array.isArray(val)) {
        val = [val]
      }

      const preparedValue = val.map(el => Number(el))

      const checked = this.filter.choices
        .filter(el => preparedValue.find(v => v === el.value))
        .map(el => el.value)

      return checked
    },
  },
}
</script>
